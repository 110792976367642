<template>
  <div class="about col_" v-title data-title="About">
    <v-container>
      <section class="top">
        <v-row align="start">
          <v-col cols="12" md="7" lg="7">
            <div class="zero animated fadeInUp">
              <h1 class="title-1">
                {{ $t("ABOUT") }}
              </h1>
              <p class="content my-15">
                {{ $t("aboutMissionTitle") }}
              </p>
              <p class="content">
                {{ $t("aboutPassionTitle") }}
              </p>
            </div>
          </v-col>

          <v-col cols="12" md="5" lg="5" class="one animated fadeInUp">
            <div class="right white black--text px-9 py-9">
              <h3>{{ $t("h3") }}</h3>
              <p>{{ $t("pOne") }}</p>
              <p>{{ $t("pTow") }}</p>
              <p>{{ $t("pTherr") }}</p>
            </div>
          </v-col>
        </v-row>
      </section>
      <div class="nb" style="width: 85%"></div>
      <section class="my-9 py-9">
        <v-row class="video-box">
          <v-col
            cols="12"
            md="6"
            class="aos-init aos-animate"
            data-aos="flip-left"
          >
            <v-responsive :aspect-ratio="16 / 9">
              <iframe
                class="u-aspect-16-9__inner"
                src="https://www.youtube-nocookie.com/embed/l44z35vabvA"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
              ></iframe>
            </v-responsive>
          </v-col>
          <v-col cols="12" md="6" class="text-center word">
            <h2 class="t1 t-bold white--text">{{ $t("bodyTitle") }}</h2>
            <p class="t1 t-bold grey--text">{{ $t("second") }}</p>
            <p class="white--text">{{ $t("con") }}</p>
          </v-col>
        </v-row>
        <v-row class="video-box">
          <v-col cols="12" md="6" class="text-center word">
            <h2 class="t1 t-bold white--text">{{ $t("textTitle") }}</h2>
            <p class="t1 t-bold grey--text">{{ $t("bodysecond") }}</p>
            <p class="white--text">{{ $t("bottomCon") }}</p>
          </v-col>
          <v-col cols="12" md="6" data-aos="flip-right">
            <v-responsive :aspect-ratio="16 / 9">
              <iframe
                class="u-aspect-16-9__inner"
                src="https://www.youtube-nocookie.com/embed/lH1pEE0W3ug"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
              ></iframe>
            </v-responsive>
          </v-col>
        </v-row>
      </section>

      <section class="four">
        <h1 class="title-1">{{ $t("fourHead") }}</h1>
        <!-- <div class="title">{{ $t("fourTitle") }}</div>
        <div class="content col_">
          <div class="item row">{{ $t("fourOne") }}</div>
          <div class="item row">{{ $t("fourTow") }}</div>
          <div class="item row">{{ $t("fourTherr") }}</div>
          <div class="item row">{{ $t("four") }}</div>
          <div class="item row">{{ $t("fourFive") }}</div>
          <div class="item row">{{ $t("fourSix") }}</div>
        </div> -->
        <div class="o-layout__item o-layout o-layout--centered-h stack">
          <div
            class="o-layout__item u-12 u-10@wide aos-init aos-animate"
            data-aos="slide-up"
          >
            <div class="c-web3_tech_stack">
              <div class="c-web3_tech_stack-schema c-web3_tech_stack__content">
                <div
                  class="c-web3_tech_stack-schema-layer c-web3_tech_stack-schema__layer"
                >
                  <div class="c-web3_tech_stack-schema-layer__title">
                    <h4>L4</h4>
                  </div>
                  <div class="c-web3_tech_stack-schema-layer__content">
                    <div class="c-web3_tech_stack-schema-layer-header">
                      <div
                        class="c-web3_tech_stack-schema-layer-header__item js-web3_tech_stack-layer-toggle"
                      >
                        <h5>
                          {{ $t("fourL4") }}
                        </h5>
                      </div>
                    </div>
                    <div
                      class="c-web3_tech_stack-schema-layer-toggle-content js-web3_tech_stack-layer-toggle-content"
                    >
                      <p>
                        {{ $t("fourL4detail1") }}
                      </p>
                      <p class="t-color-light">
                        {{ $t("fourL4detail2") }}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="c-web3_tech_stack-schema-layer c-web3_tech_stack-schema__layer"
                >
                  <div class="c-web3_tech_stack-schema-layer__title">
                    <h4>L3</h4>
                  </div>
                  <div class="c-web3_tech_stack-schema-layer__content">
                    <div class="c-web3_tech_stack-schema-layer-header">
                      <div
                        class="c-web3_tech_stack-schema-layer-header__item js-web3_tech_stack-layer-toggle"
                      >
                        <h5>
                          {{ $t("fourL3") }}
                        </h5>
                      </div>
                    </div>
                    <div
                      class="c-web3_tech_stack-schema-layer-toggle-content js-web3_tech_stack-layer-toggle-content"
                    >
                      <p>
                        {{ $t("fourL3detail1") }}
                      </p>
                      <p class="t-color-light">
                        {{ $t("fourL3detail2") }}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="c-web3_tech_stack-schema-layer c-web3_tech_stack-schema__layer"
                >
                  <div class="c-web3_tech_stack-schema-layer__title">
                    <h4>L2</h4>
                  </div>

                  <div class="c-web3_tech_stack-schema-layer__content">
                    <div
                      class="c-web3_tech_stack-schema-layer-header c-web3_tech_stack-schema-layer-header--multiple"
                    >
                      <h5>{{ $t("fourTitle") }}</h5>
                      <div
                        class="c-web3_tech_stack-schema-layer-header-sub c-web3_tech_stack-schema-layer-header-sub--6"
                      >
                        <div
                          class="c-web3_tech_stack-schema-layer-header-sub__item js-web3_tech_stack-layer-toggle"
                        >
                          {{ $t("fourOne") }}
                        </div>
                        <div
                          class="c-web3_tech_stack-schema-layer-header-sub__item js-web3_tech_stack-layer-toggle"
                        >
                          {{ $t("fourTow") }}
                        </div>
                        <div
                          class="c-web3_tech_stack-schema-layer-header-sub__item js-web3_tech_stack-layer-toggle"
                        >
                          {{ $t("fourTherr") }}
                        </div>
                        <div
                          class="c-web3_tech_stack-schema-layer-header-sub__item js-web3_tech_stack-layer-toggle"
                        >
                          {{ $t("four") }}
                        </div>
                        <div
                          class="c-web3_tech_stack-schema-layer-header-sub__item js-web3_tech_stack-layer-toggle"
                        >
                          {{ $t("fourFive") }}
                        </div>
                        <div
                          class="c-web3_tech_stack-schema-layer-header-sub__item js-web3_tech_stack-layer-toggle"
                        >
                          {{ $t("fourSix") }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="c-web3_tech_stack-schema-layer-toggle-content js-web3_tech_stack-layer-toggle-content"
                    >
                      <p>
                        {{ $t("fourTitle_detail") }}
                      </p>
                      <div
                        class="c-web3_tech_stack-schema-layer-toggle-content-sub t-color-light"
                      >
                        <div
                          class="c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                        >
                          <p>
                            {{ $t("fourDetail1") }}
                          </p>
                        </div>
                        <div
                          class="c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                        >
                          <p>
                            {{ $t("fourDetail2") }}
                          </p>
                        </div>
                        <div
                          class="c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                        >
                          <p>
                            {{ $t("fourDetail3") }}
                          </p>
                        </div>
                        <div
                          class="c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                        >
                          <p>
                            {{ $t("fourDetail4") }}
                          </p>
                        </div>
                        <div
                          class="c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                        >
                          <p>
                            {{ $t("fourDetail5") }}
                          </p>
                        </div>
                        <div
                          class="c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                        >
                          <p>
                            {{ $t("fourDetail6") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="c-web3_tech_stack-schema-layer c-web3_tech_stack-schema__layer"
              >
                <div class="c-web3_tech_stack-schema-layer__title">
                  <h4>L1</h4>
                </div>
                <div class="c-web3_tech_stack-schema-layer__content">
                  <div
                    class="c-web3_tech_stack-schema-layer-header c-web3_tech_stack-schema-layer-header--multiple c-web3_tech_stack-schema-layer-header--l1"
                  >
                    <div
                      class="c-web3_tech_stack-schema-layer-header-sub c-web3_tech_stack-schema-layer-header-sub--4"
                    >
                      <div
                        class="c-web3_tech_stack-schema-layer-header-sub__item js-web3_tech_stack-layer-toggle"
                      >
                        {{ $t("fourL11") }}
                      </div>
                      <div
                        class="c-web3_tech_stack-schema-layer-header-sub__item js-web3_tech_stack-layer-toggle"
                      >
                        {{ $t("fourL12") }}
                      </div>
                      <div
                        class="c-web3_tech_stack-schema-layer-header-sub__item js-web3_tech_stack-layer-toggle"
                      >
                        {{ $t("fourL13") }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="c-web3_tech_stack-schema-layer-toggle-content js-web3_tech_stack-layer-toggle-content"
                  >
                    <p>
                      {{ $t("fourL1TitleDetail") }}
                    </p>
                    <div
                      class="c-web3_tech_stack-schema-layer-toggle-content-sub t-color-light"
                    >
                      <div
                        class="c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                      >
                        <p>
                          {{ $t("fourL11Detail") }}
                        </p>
                      </div>
                      <div
                        class="c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                      >
                        <p>
                          {{ $t("fourL12Detail") }}
                        </p>
                      </div>
                      <div
                        class="c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                      >
                        <p>
                          {{ $t("fourL13Detail") }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="c-web3_tech_stack-schema-layer c-web3_tech_stack-schema__layer"
            >
              <div class="c-web3_tech_stack-schema-layer__title">
                <h4>L0</h4>
              </div>
              <div class="c-web3_tech_stack-schema-layer__content">
                <div
                  class="c-web3_tech_stack-schema-layer-header c-web3_tech_stack-schema-layer-header--multiple c-web3_tech_stack-schema-layer-header--l0"
                >
                  <div
                    class="c-web3_tech_stack-schema-layer-header-sub c-web3_tech_stack-schema-layer-header-sub--2"
                  >
                    <div
                      class="c-web3_tech_stack-schema-layer-header-sub__item js-web3_tech_stack-layer-toggle"
                    >
                      {{ $t("fourL01") }}
                    </div>
                    <div
                      class="c-web3_tech_stack-schema-layer-header-sub__item js-web3_tech_stack-layer-toggle"
                    >
                      {{ $t("fourL02") }}
                    </div>
                    <div
                      class="c-web3_tech_stack-schema-layer-header-sub__item js-web3_tech_stack-layer-toggle"
                    >
                      {{ $t("fourL03") }}
                    </div>
                  </div>
                </div>
                <div
                  class="c-web3_tech_stack-schema-layer-toggle-content js-web3_tech_stack-layer-toggle-content"
                >
                  <p>
                    {{ $t("fourL0TitleDetail") }}
                  </p>
                  <div
                    class="c-web3_tech_stack-schema-layer-toggle-content-sub t-color-light"
                  >
                    <div
                      class="c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                    >
                      <p>
                        {{ $t("fourL01Detail") }}
                      </p>
                    </div>
                    <div
                      class="c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                    >
                      <p>
                        {{ $t("fourL02Detail") }}
                      </p>
                    </div>
                    <div
                      class="c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                    >
                      <p>
                        {{ $t("fourL03Detail") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <img src="@/assets/img/about-bg.png" alt="" class="position" /> -->
      <ButtomNav></ButtomNav>
    </v-container>
  </div>
</template>
<script>
import ButtomNav from "@/components/buttomNav.vue";
import t from "jquery";
export default {
  components: {
    ButtomNav,
  },
  mounted() {
    t(document).ready(function () {
      t(".js-web3_tech_stack-layer-toggle").click(function (e) {
        if (
          t(this).parent().hasClass("c-web3_tech_stack-schema-layer-header-sub")
        )
          if (t(this).hasClass("is-active"))
            t(this)
              .closest(".c-web3_tech_stack-schema-layer__content")
              .find(".js-web3_tech_stack-layer-toggle-content")
              .slideToggle(400),
              t(this).removeClass("is-active");
          else {
            t(this)
              .siblings(".js-web3_tech_stack-layer-toggle")
              .removeClass("is-active"),
              t(this).addClass("is-active");
            let e = t(this).index();
            t(this)
              .closest(".c-web3_tech_stack-schema-layer__content")
              .find(".c-web3_tech_stack-schema-layer-toggle-content-sub__item")
              .hide(),
              t(this)
                .closest(".c-web3_tech_stack-schema-layer__content")
                .find(
                  ".c-web3_tech_stack-schema-layer-toggle-content-sub__item"
                )
                .eq(e)
                .show(),
              t(this)
                .closest(".c-web3_tech_stack-schema-layer__content")
                .find(".js-web3_tech_stack-layer-toggle-content")
                .slideDown(400);
          }
        else
          t(this)
            .closest(".c-web3_tech_stack-schema-layer__content")
            .toggleClass("is-open"),
            t(this)
              .closest(".c-web3_tech_stack-schema-layer__content")
              .find(".c-web3_tech_stack-schema-layer-toggle-content")
              .slideToggle(400);
      });
    });
  },
};
</script>
<style lang="less" scoped>
.about {
  width: 100%;

  .top {
    margin-top: 5vh;
    margin-bottom: 20vh;
  }
  .zero {
    // margin: 18vh 0 2vh;
    padding-right: 20vh;

    .content {
      color: #fff;
      font-size: 1.6vw;
      line-height: 1.5;
      font-weight: 500;
    }
  }
  > section {
    box-sizing: border-box;
  }
  > section + section {
    margin-top: 10vh;
  }
  .one {
    .right {
      box-sizing: border-box;
      line-height: 1.4;
      > h3 {
        margin-bottom: 5vh;
      }
      > p {
        margin-left: 20px;
        font-size: 100%;
        position: relative;
      }
      p::before {
        content: "\2014";
        position: absolute;
        left: -20px;
        color: #000000;
      }
    }
  }
  .video-box {
    margin: 10vw 0;
    .t1 {
      font-size: 26px;
    }
    .word {
      padding: 8vh;
    }
  }
  > .three {
    color: #fff;
    margin: 10vh 0;
    > .item {
      width: 100%;
      > div {
        width: 100%;
      }
      .text {
        width: 100%;
        // background: #0ff;
        // padding: 9vh 0;
        box-sizing: border-box;
        > .title {
          margin: 1vh 0;
          font-size: 1.5vw;
        }
        > .second {
          font-size: 0.9vw;
          padding: 1.5vh 0;
        }
        > .con {
          width: 100%;
          color: #000;
          font-size: 0.85vw;
          padding: 2vh 2vw;
          line-height: 3vh;
          background: #fff;
          box-sizing: border-box;
        }
      }
    }
  }
  .stack {
    margin: 10vw 0;
  }
  > .position {
    position: absolute;
    width: 26vw;
    height: 45vh;
    right: 0vw;
    top: 66vh;
  }
}
iframe {
  width: 100%;
  height: 100%;
  // width: 560px;
  // height: 315px;
}
@media screen and (max-width: 940px) {
  // 移动设备
  // iframe {
  //   width: 330px;
  //   height: 185.63px;
  // }
  .about {
    .title-1 {
      font-size: 25px;
    }
    .zero {
      padding-right: 0vh;
      .content {
        font-size: 5vw;
      }
    }
  }

  .two {
    margin: 0 !important;
    .left {
      font-size: 5vw !important;
      width: 100% !important;
    }
    > .right {
      width: 100% !important;
      .item {
        width: 100% !important;
        align-items: center !important;
        > .head {
          width: 70vw !important;
          height: 70vw !important;
          margin-top: 6vh;
        }
      }
    }
  }
}
.c-web3_tech_stack-schema-layer {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.c-web3_tech_stack-schema-layer__title {
  color: #b3b3b3;
}
.c-web3_tech_stack-schema__layer {
  margin-bottom: 1.25em;
}
.c-web3_tech_stack-schema-layer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.c-web3_tech_stack-schema-layer > div {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}
.c-web3_tech_stack-schema-layer > div {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}
@media all and (min-width: 58.75em) {
  .c-web3_tech_stack-schema__layer {
    margin-bottom: 0.625em;
  }
  .c-web3_tech_stack-schema-layer .c-web3_tech_stack-schema-layer__title {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% / 12 * 2);
    flex: 0 0 calc(100% / 12 * 2);
  }
  .c-web3_tech_stack-schema-layer .c-web3_tech_stack-schema-layer__content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% / 12 * 10);
    flex: 0 0 calc(100% / 12 * 10);
  }
}

.t-color-light {
  color: #ccc;
}
.c-web3_tech_stack-schema-layer__title {
  margin-bottom: 1.25em;
}

.c-web3_tech_stack-schema-layer-header h5 {
  margin-bottom: 2.5em;
  width: 100%;
  -webkit-box-flex: 100%;
  -ms-flex: 100%;
  flex: 100%;
}

.c-web3_tech_stack-schema-layer-header__item {
  border: solid 1px #b3b3b3;
  padding: 0.625em;
  cursor: pointer;
}

.c-web3_tech_stack-schema-layer-header__item:hover,
.is-open .c-web3_tech_stack-schema-layer-header__item {
  color: black;
  background: white;
  border: solid 1px white;
}

.c-web3_tech_stack-schema-layer-header__item:hover
  .c-web3_tech_stack-schema-layer-toggle-sub__item,
.is-open
  .c-web3_tech_stack-schema-layer-header__item
  .c-web3_tech_stack-schema-layer-toggle-sub__item {
  border: solid 1px black;
}

.c-web3_tech_stack-schema-layer-header--multiple {
  padding: 0.625em;
  border: solid 1px #b3b3b3;
}

.c-web3_tech_stack-schema-layer-header--l1 {
  padding: 0;
  border: none;
}

.c-web3_tech_stack-schema-layer-header--l0 {
  padding: 0;
  border: none;
}

.c-web3_tech_stack-schema-layer-header-sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media all and (max-width: 43.0625em) {
  .c-web3_tech_stack-schema-layer-header-sub {
    font-size: 2.8vw;
  }
}

@media all and (min-width: 58.75em) and (max-width: 91.1875em) {
  .c-web3_tech_stack-schema-layer-header-sub {
    font-size: 1.2vw;
  }
}

@media all and (min-width: 91.25em) and (max-width: 99.9375em) {
  .c-web3_tech_stack-schema-layer-header-sub {
    font-size: 1.2vw;
  }
}

.c-web3_tech_stack-schema-layer-header-sub__item {
  background: black;
  padding: 0.625em;
  padding-bottom: 3.75em;
  border: solid 1px #b3b3b3;
  cursor: pointer;
}

.c-web3_tech_stack-schema-layer-header-sub__item.is-active {
  background: white;
  color: black;
  border: solid 1px white;
}

.c-web3_tech_stack-schema-layer-header-sub__item:hover {
  background: white;
  color: black;
  border: solid 1px white;
}

.c-web3_tech_stack-schema-layer-header-sub--2
  .c-web3_tech_stack-schema-layer-header-sub__item {
  width: calc(100% / 4 - 0.625em);
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% / 4 - 0.625em);
  flex: 0 0 calc(100% / 4 - 0.625em);
  border: solid 1px #b3b3b3;
}

.c-web3_tech_stack-schema-layer-header-sub--2
  .c-web3_tech_stack-schema-layer-header-sub__item:hover,
.c-web3_tech_stack-schema-layer-header-sub--2
  .c-web3_tech_stack-schema-layer-header-sub__item.is-active {
  border: solid 1px white;
}

.c-web3_tech_stack-schema-layer-header-sub--2
  .c-web3_tech_stack-schema-layer-header-sub__item:nth-child(2) {
  width: calc(100% / 2 - 0.625em);
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% / 2 - 0.625em);
  flex: 0 0 calc(100% / 2 - 0.625em);
}

.c-web3_tech_stack-schema-layer-header-sub--4 {
  height: 13.75em;
  position: relative;
}

.c-web3_tech_stack-schema-layer-header-sub--4
  .c-web3_tech_stack-schema-layer-header-sub__item:first-child {
  width: calc(100% / 4 * 3 - 0.3125em);
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% / 4 * 3 - 0.3125em);
  flex: 0 0 calc(100% / 4 * 3 - 0.3125em);
}

.c-web3_tech_stack-schema-layer-header-sub--4
  .c-web3_tech_stack-schema-layer-header-sub__item:nth-child(2) {
  padding-top: 1.5625em;
  padding-left: 1.5625em;
  position: absolute;
  bottom: 0;
  width: calc(100% / 2 + 0.3125em);
  height: 65%;
  right: calc(100% / 4 + 0.3125em);
  z-index: 1;
  border: none;
  border-left: solid 1px #b3b3b3;
  border-top: solid 1px #b3b3b3;
}

.c-web3_tech_stack-schema-layer-header-sub--4
  .c-web3_tech_stack-schema-layer-header-sub__item:nth-child(2):before {
  content: "";
  width: calc(100% - 0.9375em);
  height: calc(100% - 0.9375em);
  background: black;
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  border: solid 1px #b3b3b3;
}

.c-web3_tech_stack-schema-layer-header-sub--4
  .c-web3_tech_stack-schema-layer-header-sub__item:nth-child(2):hover {
  background: black;
}

.c-web3_tech_stack-schema-layer-header-sub--4
  .c-web3_tech_stack-schema-layer-header-sub__item:nth-child(2):hover:before {
  background: white;
  border: solid 1px white;
}

.c-web3_tech_stack-schema-layer-header-sub--4
  .c-web3_tech_stack-schema-layer-header-sub__item.is-active:nth-child(2) {
  background: black;
}

.c-web3_tech_stack-schema-layer-header-sub--4
  .c-web3_tech_stack-schema-layer-header-sub__item.is-active:nth-child(2):before {
  background: white;
  border: solid 1px white;
}

.c-web3_tech_stack-schema-layer-header-sub--4
  .c-web3_tech_stack-schema-layer-header-sub__item:last-child {
  width: calc(100% / 4 - 0.625em);
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% / 4 - 0.625em);
  flex: 0 0 calc(100% / 4 - 0.625em);
}

.c-web3_tech_stack-schema-layer-header-sub--6
  .c-web3_tech_stack-schema-layer-header-sub__item {
  width: calc(100% / 3 - 0.625em);
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% / 3 - 0.625em);
  flex: 0 0 calc(100% / 3 - 0.625em);
  margin-bottom: 0.625em;
}

@media all and (min-width: 58.75em) {
  .c-web3_tech_stack-schema-layer-header-sub--6
    .c-web3_tech_stack-schema-layer-header-sub__item {
    width: calc(100% / 6 - 0.625em);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% / 6 - 0.625em);
    flex: 0 0 calc(100% / 6 - 0.625em);
    margin-bottom: 0;
  }
}

.c-web3_tech_stack-schema-layer-toggle-content {
  display: none;
  padding: 0.625em;
  padding-top: 2.5em;
}

.c-web3_tech_stack-schema-layer-toggle-content p {
  margin-bottom: 1.25em;
}

@media all and (min-width: 58.75em) {
  .c-web3_tech_stack-schema-layer-toggle-content p {
    max-width: 80%;
  }
}

.c-web3_tech_stack-schema-layer-toggle-content-sub__item {
  display: none;
}
</style>
